/* eslint-disable object-shorthand */
/* eslint-disable no-return-assign */
/* eslint-disable camelcase */
/* eslint-disable no-sequences */
/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
import {
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../components/hooks/BaseTableComponents'
import colors from '../../../assets/colors'
import { mdiCashClock, mdiDelete, mdiDotsVertical, mdiNoteEdit } from '@mdi/js'
import Icon from '@mdi/react'
import { exportFormatedMoney, renderRoute } from '../../../util/utils'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { useConfirm } from 'material-ui-confirm'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBilling, remove } from '../BillingActions'

const useStyles = makeStyles({
  centerCost: {
    height: '26px',
    width: '2px',
    borderRadius: '5px',
  },
  status: {
    borderRadius: '5px',
    width: '82px',
    height: '19px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFF',
    fontSize: '12px',
    textAlign: 'center',
    lineHeight: '12px',
    padding: '4px 0',
  },
  ref: {
    width: '34px',
    height: '34px',
    position: 'absolute',
    backgroundColor: '#FFFF',
    transform: 'rotate(45deg)',
    right: '7px',
    '&:hover': {
      backgroundColor: '#FFFF',
    },
  },
  menuList: {
    padding: '10px',
  },
  menuItem: {
    padding: '0 2px',
    height: 'auto',
    lineHeight: '1',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
  },
  menuItemLi: {
    padding: 0,
    margin: 0,
    borderRadius: 0,
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
    fontSize: '14px',
  },
  menuItemLiIcon: {
    marginRight: '12px',
  },
  tableContainer: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 280px)',
  },
})

const CreateData = (
  id,
  costCenter,
  value,
  dueDate,
  payment,
  contact,
  bank,
  isPaid
) => {
  return {
    id,
    costCenter,
    value,
    dueDate,
    payment,
    contact,
    bank,
    isPaid,
  }
}

const BalanceList = ({ type = 'expenses', data, permissions, typeGeneric }) => {
  const classes = useStyles()
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const filters = useSelector(state => state.billing.filters)

  const rows = []
  const [open, setOpen] = useState({
    id: '',
  })

  const anchorRefs = useRef([])

  const handleToggle = id => {
    if (open?.id === id) {
      return setOpen({
        id: '',
      })
    }

    setOpen({
      ...open,
      id: id,
    })
  }

  const handleClose = (event, index) => {
    if (
      anchorRefs.current[index] &&
      anchorRefs.current[index].contains(event.target)
    ) {
      return
    }

    setOpen({
      id: '',
    })
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen({
        id: '',
      })
    }
  }

  const confirmDelete = id => {
    confirm({
      description: 'Após ser apagado o conteúdo não poderá ser recuperado?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      dispatch(remove(id))
      dispatch(fetchBilling(filters))
    })
  }

  // const laundryOrClient = row => {
  //   if (!row.service) return null

  //   if (row?.person?.type === 'client')
  //     return `/attendance/${row.service.id}/edit`
  //   if (row?.person?.type === 'laundry')
  //     return `/laundry-os/${row.service.id}/details`

  //   return null
  // }

  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCellHeader />
            <StyledTableCellHeader>Contato Financeiro</StyledTableCellHeader>
            <StyledTableCellHeader>Valor</StyledTableCellHeader>
            <StyledTableCellHeader>Vencimento</StyledTableCellHeader>
            <StyledTableCellHeader>Pagamento</StyledTableCellHeader>
            <StyledTableCellHeader>Centro de custo</StyledTableCellHeader>
            <StyledTableCellHeader>Banco</StyledTableCellHeader>
            <StyledTableCellHeader>Status</StyledTableCellHeader>
            <StyledTableCellHeader>Ações</StyledTableCellHeader>
          </TableRow>
        </TableHead>

        <TableBody>
          {Array.isArray(data) &&
            data?.map(row => (
              <TableRow key={row?.id}>
                <StyledTableCellBody>
                  <Box
                    className={classes.centerCost}
                    style={
                      type == 'expenses'
                        ? { backgroundColor: colors.error }
                        : { backgroundColor: colors.sucess }
                    }
                  />
                </StyledTableCellBody>
                <StyledTableCellBody>{row?.contact}</StyledTableCellBody>
                <StyledTableCellBody>
                  {exportFormatedMoney(row?.value)}
                </StyledTableCellBody>
                <StyledTableCellBody>{row?.due_date}</StyledTableCellBody>
                <StyledTableCellBody>{row?.payment}</StyledTableCellBody>
                <StyledTableCellBody>{row?.cost_center}</StyledTableCellBody>

                <StyledTableCellBody>{row?.bank_name}</StyledTableCellBody>
                <StyledTableCellBody>
                  <Box
                    className={classes.status}
                    style={{
                      backgroundColor: row?.is_paid
                        ? colors.sucess
                        : colors.error,
                    }}
                  >
                    {row?.is_paid ? 'Pago' : 'Em aberto'}
                  </Box>
                </StyledTableCellBody>
                <StyledTableCellBody>
                  <IconButton
                    ref={el => (anchorRefs.current[row?.id] = el)}
                    key={row.id}
                    id={row?.id}
                    aria-controls={
                      open?.id ? `menu-list-grow-${row.id}` : undefined
                    }
                    aria-haspopup="true"
                    onClick={() => {
                      handleToggle(row.id)
                    }}
                  >
                    <Icon path={mdiDotsVertical} size={1} />
                  </IconButton>

                  <Popper
                    open={open?.id === row?.id}
                    role={undefined}
                    anchorEl={anchorRefs.current[row?.id]}
                    transition
                    disablePortal
                    style={{
                      zIndex: 99999999,
                    }}
                    placement="bottom-end"
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement == 'bottom' ? 'left-end' : 'top-end',
                        }}
                      >
                        <Paper className={classes.paper}>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open?.id}
                              //   id={`menu-list-grow-${row.id}`}
                              onKeyDown={handleListKeyDown}
                              className={classes.menuList}
                            >
                              <MenuItem
                                className={classes.ref}
                                style={
                                  placement === 'bottom-end'
                                    ? { top: '-2px' }
                                    : { bottom: '-2px' }
                                }
                              />
                              {renderRoute(['update-billing'], permissions) && (
                                <MenuItem
                                  onClick={handleClose}
                                  className={classes.menuItem}
                                >
                                  <IconButton
                                    className={classes.menuItemLi}
                                    aria-label="edit"
                                    component={Link}
                                    to={{
                                      pathname: `/billing/${row?.id}/edit`,
                                      state: {
                                        typeGeneric: typeGeneric,
                                      },
                                    }}
                                  >
                                    <Icon
                                      path={mdiNoteEdit}
                                      size={1}
                                      className={classes.menuItemLiIcon}
                                    />
                                    Editar
                                  </IconButton>
                                </MenuItem>
                              )}
                              {renderRoute(['delete-billing'], permissions) && (
                                <MenuItem
                                  onClick={handleClose}
                                  className={classes.menuItem}
                                >
                                  <IconButton
                                    className={classes.menuItemLi}
                                    onClick={() => confirmDelete(row.id)}
                                  >
                                    <Icon
                                      path={mdiDelete}
                                      size={1}
                                      className={classes.menuItemLiIcon}
                                    />
                                    Deletar
                                  </IconButton>
                                </MenuItem>
                              )}
                              <MenuItem
                                onClick={handleClose}
                                className={classes.menuItem}
                              >
                                <IconButton
                                  aria-label="close"
                                  component={Link}
                                  to={{
                                    pathname: `billing/${row.id}/close`,
                                    state: {
                                      typeGeneric: typeGeneric,
                                    },
                                  }}
                                  className={classes.menuItemLi}
                                >
                                  <Icon
                                    path={mdiCashClock}
                                    size={1}
                                    className={classes.menuItemLiIcon}
                                  />
                                  Definir pagamento
                                </IconButton>
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </StyledTableCellBody>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BalanceList
